<template>
    <h1 class="page-header">
        {{ $t( "pages.boat.pageHeadline" ) }}
    </h1>

    <p class="body-text" v-html="$t( 'pages.boat.paragraph1' )"></p>
    <p class="body-text" v-html="$t( 'pages.boat.paragraph2' )"></p>
    <p class="body-text" v-html="$t( 'pages.boat.paragraph3' )"></p>
    <p class="body-text" v-html="$t( 'pages.boat.paragraph4' )"></p>
    <p class="body-text" v-html="$t( 'pages.boat.paragraph5' )"></p>

    <div class="d-flex flex-row justify-content-end">
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('contact')"
        >
            <n-icon class="me-2">
                <link-icon />
            </n-icon>
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </div>
</template>

<script>
import { ArrowRight as LinkIcon } from '@vicons/carbon'

export default {
    components: { LinkIcon },
    methods: {
        linkTo( name ) {
            return this.$helper.routerJumpTo( this, name )
        }
    }
}
</script>

<style scoped>

</style>